<script>
import UploadImage from "@/components/UploadImage/UploadImage";
import { putBannerEdit, postBanner } from "@/newApi/market";
import { getGoodsSelect } from "@/newApi/commit";
export default {
  name: "deawerMarket",
  props: {
    type: {
      type: String,
      default: "add",
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      options: [], //商品下拉

      list: [
        { value: 1, label: "商品详情页面" },
        { value: 3, label: "h5页面" },
        { value: 4, label: "指定小程序页面" },
        { value: 5, label: "不跳转" },
      ],
    };
  },
  components: {
    UploadImage,
  },
  //生命周期
  created() {
    console.log(this.info);
    this._getGoodsSelect();
  },
  methods: {
    _getGoodsSelect() {
      getGoodsSelect().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    onSuccessCover(urlAddr) {
      console.log(urlAddr);
      this.info.cover = urlAddr;
    },
    //取消
    closeDrawer() {
      let drawers = false;
      this.$emit("closeDrawer", drawers);
      this.$emit("getList");
    },
    //新增
    async _postBanner(data) {
      let res = await postBanner(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.closeDrawer();
        this.$emit("getList");
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
        this.info.cover = [this.info.cover];
      }
    },
    //修改
    async _putBannerEdit(data) {
      let res = await putBannerEdit(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.closeDrawer();
        this.$emit("getList");
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
        this.info.cover = [this.info.cover];
      }
    },
    //确定保存
    handleConfirm(row) {
      if (!this.info.type) return this.$message.error("请选择banner类型");
      else if (!this.info.title) return this.$message.error("请输入banner标题");
      else if (!this.info.cover) return this.$message.error("请上传banner图片");
      else if (!this.info.sort) return this.$message.error("请输入排序");
      else if (!this.info.status) return this.$message.error("请选择状态");
      else if ((this.info.type == 3 || this.info.type == 4) && !this.info.link)
        return this.$message.error("请输入跳转链接");
      else if (this.info.type == 1 && !this.info.goods_id)
        return this.$message.error("请选择跳转商品");
      else {
        //判断cover是字符串还是数组
        if (typeof this.info.cover == "string") {
          this.info.cover = this.info.cover;
        } else {
          this.info.cover = this.info.cover[0];
        }
        if (this.type == "add") {
          this._postBanner(this.info);
        } else {
          if (typeof this.info.cover == "string") {
            this.info.cover = this.info.cover;
          } else {
            this.info.cover = this.info.cover[0];
          }
          console.log("edit");
          this.info.cover = this.info.cover;
          this._putBannerEdit(this.info);
        }
      }
    },
  },
};
</script>

<template>
  <div class="gg-container">
    <div style="margin-bottom:20px;">
      <span style="font-size:25px;border-bottom:3px solid #1684FC;"
        >新增banner</span
      >
    </div>
    <div class="content">
      <div style="margin-bottom:20px;">
        <span style="width:350px;margin-bottom:20px;"
          ><span style="color:red">*</span>banner类型</span
        >
        <el-select
          filterable
          clearable
          v-model="info.type"
          placeholder="请选择"
          size="mini"
        >
          <el-option
            v-for="item in list"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>banner标题</span
        >
        <el-input v-model="info.title" size="mini" class="input"></el-input>
      </div>
      <div style="margin-bottom:20px;display:flex;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>图片</span
        >
        <UploadImage
          :limit="1"
          :url="info.cover"
          @on-success="onSuccessCover"
        ></UploadImage>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>排序</span
        >
        <el-input v-model="info.sort" size="mini" class="input"></el-input>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>状态</span
        >
        <div>
          <el-radio v-model="info.status" label="1">是</el-radio>
          <el-radio v-model="info.status" label="0">否</el-radio>
        </div>
      </div>
      <div style="margin-bottom:20px;" v-if="info.type == 3 || info.type == 4">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>跳转链接</span
        >
        <el-input v-model="info.link" size="mini" class="input"></el-input>
      </div>
      <div style="margin-bottom:20px;" v-if="info.type == 1">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red"></span>跳转商品</span
        >
        <el-select
          filterable
          clearable
          v-model="info.goods_id"
          placeholder="请选择商品"
          size="mini"
          style="width:200px;margin:0 15px;"
        >
          <el-option
            v-for="item1 in options"
            :key="item1.id"
            :label="item1.goods_name"
            :value="item1.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="closeDrawer, (info = {})"
        >取 消</el-button
      >
      <el-button type="primary" size="small" @click="handleConfirm('info')">{{
        type == "add" ? "确 定" : "修 改"
      }}</el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.input {
  width: 200px;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.footer {
  position: absolute;
  bottom: 100px;
  right: 100px;
}
</style>
